body {
  margin: 0;
  font-family: 'Segoe UI', sans-serif;
  background-color: #f8f9fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1,
h2,
h3 {
  margin-bottom: 0.5rem;
  color: #333;
}

button {
  padding: 10px 16px;
  font-size: 1rem;
  margin-top: 10px;
  border: none;
  border-radius: 6px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

select,
input {
  width: 100%;
  max-width: 100%;
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 1rem;
  border-radius: 6px;
  border: 1px solid #ccc;
  box-sizing: border-box;
}
